:root {
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  box-sizing: border-box;
  overflow-x: hidden;
  --buttonBack: #f17070;
}
iframe#webpack-dev-server-client-overlay {
  display: none !important;
}
.btn-shine {
  display: flex;
  color: #ffffff;
  background: linear-gradient(to right, #ffffff 0, #fffefe 10%, #3c3a3a 20%);
  /* background-position: 0; */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 3s infinite ease-in-out;
  animation-fill-mode: forwards;
  -webkit-text-size-adjust: none;
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
  white-space: nowrap;
}
.wappBar {
  height: 50px;
  width: 90px;
  position: sticky;
  top: 50vh;
  left: 95%;
  background: rgba(221, 219, 219, 0.6);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: green;
}

@-moz-keyframes shine {
  0% {
    background-position: 0;
  }
  60% {
    background-position: 180px;
  }
  100% {
    background-position: 0;
  }
}
@-webkit-keyframes shine {
  0% {
    background-position: 0;
  }
  60% {
    background-position: 180px;
  }
  100% {
    background-position: 0;
  }
}
@-o-keyframes shine {
  0% {
    background-position: 0;
  }
  60% {
    background-position: 180px;
  }
  100% {
    background-position: 0;
  }
}
@keyframes shine {
  0% {
    background-position: 0;
  }
  60% {
    background-position: 180px;
  }
  100% {
    background-position: 0;
  }
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
